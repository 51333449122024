/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listCourseUsers = /* GraphQL */ `
  query ListCourseUser(
    $filter: ModelCourseUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        courseId
        messages(filter: { seen: { eq: FALSE } }) {
          items {
            id
            message
            userCourseId
            sender {
              id
              type
            }
            createdAt
          }
        }
        modulePartProgresses {
          items {
            id
            status
            modulePartId
          }
        }
        course {
          orgId
          user {
            org {
              name
            }
          }
          name
          description
          status
          availability
          categoryId
          category {
            name
          }
          image {
            bucket
            region
            key
          }
          available
          ownerId
          courseModules {
            items {
              id
              name
              description
              orderNumber
              duration
              parts {
                items {
                  id
                  name
                  description
                  status
                  type
                  file {
                    key
                    region
                    bucket
                  }
                  labId
                  lab {
                    id
                    name
                    provider
                    status
                    userId
                    public
                    shared
                    orgId
                    createdAt
                    updatedAt
                  }
                  quiz {
                    name
                    duration
                  }
                  duration
                  expiry
                  inactivityExpiry
                  initialLabCount
                  minLabCount
                }
              }
            }
            nextToken
          }
          courseUsers {
            items {
              id
              user {
                id
              }
            }
            nextToken
          }
          id
          createdAt
          updatedAt
        }
        userId
        orgId
        user {
          id
          email
          name
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        courseProgress
        id
        createdAt
        updatedAt
        courseCourseUsersId
      }
      nextToken
    }
  }
`;

export const getSystemTime = /* GraphQL */ `
  query GetSystemTime {
    getSystemTime
  }
`;

export const getUserById = /* GraphQL */ `
  query GetUserById(
    $id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserById(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
          specialThemes {
            items {
              themeId
              theme {
                name
              }
            }
          }
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCoursesByOrgId = /* GraphQL */ `
  query ListCoursesByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoursesByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        orgId
        name
        description
        status
        availability
        categoryId
        available
        image {
          bucket
          key
          region
        }
        category {
          name
          id
          createdAt
          updatedAt
        }
        ownerId
        user {
          id
          name
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        courseModules {
          items {
            id
            name
            description
            orderNumber
            duration
            parts {
              items {
                id
                name
                status
                description
                type
                file {
                  key
                  region
                  bucket
                }
                labId
                lab {
                  id
                  name
                  provider
                  status
                  userId
                  public
                  shared
                  orgId
                  createdAt
                  updatedAt
                }
                quiz {
                  name
                  duration
                }
                duration
                expiry
                inactivityExpiry
                initialLabCount
                minLabCount
              }
            }
          }
          nextToken
        }
        courseUsers {
          items {
            id
            user {
              id
            }
            messages(filter: { seen: { eq: FALSE } }) {
              items {
                id
                message
                userCourseId
                sender {
                  id
                  type
                  name
                }
                createdAt
              }
            }
          }
        }
        coursegroups {
          items {
            id
            groupId
          }
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listUserAssessments = /* GraphQL */ `
  query ListUserAssessments(
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          alias
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          creator {
            org {
              name
            }
          }
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          status
          multiLevel
          levels {
            items {
              tasks {
                items {
                  id
                  task {
                    id
                    recommendedPoints
                    attempts(filter: { success: { eq: true } }) {
                      items {
                        id
                        success
                        assessmentId
                        taskAttemptUserAssessmentId
                        taskAttemptTaskId
                        task {
                          recommendedPoints
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          tasks {
            items {
              id
              task {
                id
                recommendedPoints
                attempts(filter: { success: { eq: true } }) {
                  items {
                    id
                    success
                    assessmentId
                    taskAttemptUserAssessmentId
                    taskAttemptTaskId
                    task {
                      recommendedPoints
                    }
                  }
                }
              }
            }
          }
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCourseUsersByOrgId = /* GraphQL */ `
  query ListCourseUsersByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCourseUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseUsersByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        courseId
        course {
          orgId
          name
          description
          status
          availability
          categoryId
          category {
            name
          }
          image {
            bucket
            region
            key
          }
          available
          ownerId
          courseModules {
            items {
              id
              name
              description
              orderNumber
              parts {
                items {
                  id
                  name
                  status
                  description
                  type
                  file {
                    key
                    region
                    bucket
                  }
                  labId
                  lab {
                    id
                    name
                    provider
                    status
                    userId
                    public
                    shared
                    orgId
                    createdAt
                    updatedAt
                  }
                  quiz {
                    name
                    duration
                  }
                  duration
                  expiry
                  inactivityExpiry
                  initialLabCount
                  minLabCount
                }
              }
            }
            nextToken
          }
          courseUsers {
            items {
              id
            }
            nextToken
          }
          id
          createdAt
          updatedAt
        }
        userId
        orgId
        user {
          id
          email
          name
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        courseProgress
        id
        createdAt
        updatedAt
        courseCourseUsersId
      }
      nextToken
    }
  }
`;

export const getCourseUser = /* GraphQL */ `
  query GetCourseUser($id: ID!) {
    getCourseUser(id: $id) {
      courseId
      course {
        orgId
        name
        description
        categoryId
        available
        image {
          bucket
          key
          region
        }
        category {
          name
          id
          createdAt
          updatedAt
        }
        ownerId
        courseModules {
          items {
            id
            name
            description
          }
          nextToken
        }
        coursegroups {
          nextToken
        }
        courseUsers {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      userId
      orgId
      user {
        id
        email
        name
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      status
      courseProgress
      id
      createdAt
      updatedAt
      courseCourseUsersId
    }
  }
`;

export const getLabPrototype = /* GraphQL */ `
  query GetLabPrototype($id: ID!) {
    getLabPrototype(id: $id) {
      id
      name
      description
      provider
      status
      userId
      public
      shared
      vms {
        items {
          id
          labPrototypeId
          name
          template
          catalog
          cpus
          cores
          memory
          vmId
          hasInternet
          hasVdi
          captureVmUserEvents
          customConfigurations
          createdAt
          updatedAt
          networks {
            items {
              adapterType
              createdAt
              id
              ip
              labPrototypeVMId
              mac
              name
              updatedAt
            }
          }
        }
        nextToken
      }
      networks {
        items {
          id
          labPrototypeId
          name
          cidr
          hasInternet
          createdAt
          updatedAt
        }
        nextToken
      }
      competencies {
        items {
          id
          competencyId
          labPrototypeId
          createdAt
          updatedAt
        }
        nextToken
      }
      orgId
      createdAt
      updatedAt
    }
  }
`;

export const listLabPrototypes = /* GraphQL */ `
  query ListLabPrototypes(
    $filter: ModelLabPrototypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLabPrototypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        provider
        public
        shared
        orgId
        userId
        status
        networks {
          items {
            cidr
            name
            hasInternet
          }
        }
        vms {
          items {
            catalog
            cores
            cpus
            hasInternet
            hasVdi
            captureVmUserEvents
            customConfigurations
            memory
            name
            template
            networks {
              items {
                mac
                name
                adapterType
                ip
              }
            }
          }
        }
        instances(filter: { status: { eq: READY } }) {
          items {
            id
          }
        }
        tasks {
          items {
            id
          }
        }
      }
      nextToken
    }
  }
`;

export const listLabInstances = /* GraphQL */ `
  query ListLabInstances(
    $filter: ModelLabInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLabInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        instanceId
        labPrototypeId
        assessmentId
        vAppId
        copiedFromID
        status
        poweredOn
        userId
        modulePartId
        runningTime
        vms {
          items {
            id
            name
            labInstanceId
            vmId
            hasVdi
            poweredOn
          }
        }
        updatedAt
      }
      nextToken
    }
  }
`;

export const listLabInstancesByModulePartId = /* GraphQL */ `
  query ByModulePartId(
    $modulePartId: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLabInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byModulePartId(
      modulePartId: $modulePartId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        instanceId
        labPrototypeId
        vAppId
        copiedFromID
        status
        poweredOn
        modulePartId
        userId
        assessmentId
        vms {
          items {
            id
            name
            labInstanceId
            vmId
            hasVdi
            poweredOn
          }
        }
        runningTime
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getLabInstance = /* GraphQL */ `
  query GetLabInstance($id: ID!) {
    getLabInstance(id: $id) {
      id
      instanceId
      labPrototypeId
      vAppId
      copiedFromID
      status
      poweredOn
      userId
      vms {
        items {
          id
          name
          labInstanceId
          vmId
          hasVdi
          poweredOn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const listLabPrototypeVMS = /* GraphQL */ `
  query ListLabPrototypeVMS(
    $filter: ModelLabPrototypeVMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLabPrototypeVMS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        labPrototypeId
        name
        template
        catalog
        cpus
        cores
        memory
        hasInternet
        hasVdi
        captureVmUserEvents
        customConfigurations
        networks {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getVmConsole = /* GraphQL */ `
  query GetVmConsole($labInstanceVmId: ID!) {
    getVmConsole(labInstanceVmId: $labInstanceVmId) {
      location
      type
      url
      name
      keepAwakeUrl
    }
  }
`;

export const getPrototypeConsole = /* GraphQL */ `
  query GetPrototypeConsole($labPrototypeVmId: ID!) {
    getPrototypeConsole(labPrototypeVmId: $labPrototypeVmId) {
      location
      type
      url
      name
      keepAwakeUrl
    }
  }
`;

export const checkParticipantLab = /* GraphQL */ `
  query CheckParticipantLab($userId: ID, $modulePartId: ID) {
    checkParticipantLab(userId: $userId, modulePartId: $modulePartId)
  }
`;

export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      orgId
      name
      description
      categoryId
      available
      image {
        bucket
        key
        region
      }
      category {
        name
        id
        createdAt
        updatedAt
      }
      ownerId
      user {
        id
        email
        name
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      availability
      status
      courseModules {
        items {
          name
          description
          courseId
          orderNumber
          id
          duration
          parts {
            items {
              orderNumber
              id
              name
              description
              type
              file {
                key
                region
                bucket
              }
              labId
              quiz {
                id
                name
              }
              lab {
                id
                name
                provider
                status
                userId
                public
                shared
                orgId
                createdAt
                updatedAt
              }
              duration
              expiry
              inactivityExpiry
              initialLabCount
              minLabCount
            }
          }
          createdAt
          updatedAt
          courseCourseModulesId
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;

export const listVAppTemplates = /* GraphQL */ `
  query ListVAppTemplates(
    $filter: ModelVAppTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVAppTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        catalogue
        cores
        cpus
        id
        label
        memory
        orgId
        template
      }
      nextToken
    }
  }
`;

export const getLabTime = /* GraphQL */ `
  query GetLabTime($labInstanceId: ID!) {
    getLabTime(labInstanceId: $labInstanceId) {
      timeInLab
      timeRemaining
    }
  }
`;

export const listGroupsByOrgId = /* GraphQL */ `
  query ListGroupsByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        users {
          items {
            id
          }
          nextToken
        }
        orgId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCourseCategories = /* GraphQL */ `
  query ListCourseCategories(
    $filter: ModelCourseCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listVMFiles = /* GraphQL */ `
  query ListVMFiles(
    $filter: ModelVMFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVMFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        name
        status
        file {
          bucket
          key
          region
        }
        fileSize
        userId
        vAppTemplateId
        orgId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listModuleParts = /* GraphQL */ `
  query ListModuleParts(
    $filter: ModelModulePartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModuleParts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        description
        courseModuleId
        type
        quizId
        labId
        url
        file {
          bucket
          key
          region
        }
        quiz {
          id
          orgId
          name
          ownerId
          description
          duration
          multipleAttempts
          showResults
          createdAt
          updatedAt
        }
        lab {
          id
          name
          description
          provider
          status
          userId
          public
          shared
          orgId
          createdAt
          updatedAt
        }
        duration
        expiry
        inactivityExpiry
        initialLabCount
        minLabCount
        deletionMode
        id
        createdAt
        updatedAt
        courseModulePartsId
      }
      nextToken
    }
  }
`;

export const getCourseModule = /* GraphQL */ `
  query GetCourseModule($id: ID!) {
    getCourseModule(id: $id) {
      name
      description
      courseId
      orderNumber
      duration
      parts {
        items {
          name
          description
          courseModuleId
          type
          quizId
          labId
          url
          id
          createdAt
          updatedAt
          courseModulePartsId
        }
        nextToken
      }
      id
      createdAt
      updatedAt
      courseCourseModulesId
    }
  }
`;
export const listModulePartProgresses = /* GraphQL */ `
  query ListModulePartProgresses(
    $filter: ModelModulePartProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModulePartProgresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        courseUserId
        courseUser {
          courseId
          userId
          orgId
          status
          courseProgress
          id
          createdAt
          updatedAt
          courseCourseUsersId
        }
        modulePartId
        modulePart {
          name
          description
          courseModuleId
          type
          quizId
          labId
          deletionMode
          url
          id
          createdAt
          updatedAt
          courseModulePartsId
        }
        status
        startedOn
        finishedOn
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listInvitedUsersByOrg = /* GraphQL */ `
  query ListInvitedUsersByOrg(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitedUsersByOrg(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        orgId
        alias
        groupId
        assessmentId
        accepted
        participantType
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listModulePartProgressesByCourseUserId = /* GraphQL */ `
  query ListModulePartProgressesByCourseUserId(
    $courseUserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelModulePartProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModulePartProgressesByCourseUserId(
      courseUserId: $courseUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        courseUserId
        courseUser {
          courseId
          userId
          orgId
          status
          courseProgress
          id
          createdAt
          updatedAt
          courseCourseUsersId
        }
        modulePartId
        modulePart {
          name
          description
          courseModuleId
          expiry
          status
          type
          quizId
          labId
          url
          id
          createdAt
          updatedAt
          courseModulePartsId
        }
        status
        startedOn
        finishedOn
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listModulePartProgressesByCourseId = /* GraphQL */ `
  query ListModulePartProgressesByCourseId(
    $courseId: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelModulePartProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModulePartProgressesByCourseId(
      courseId: $courseId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseUserId
        courseUser {
          courseId
          userId
          orgId
          status
          courseProgress
          id
          createdAt
          updatedAt
          courseCourseUsersId
        }
        modulePartId
        modulePart {
          name
          description
          courseModuleId
          type
          quizId
          labId
          deletionMode
          url
          status
          id
          createdAt
          updatedAt
          courseModulePartsId
        }
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listQuizesByOrgId = /* GraphQL */ `
  query ListQuizesByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuizFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizesByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        name
        ownerId
        description
        duration
        multipleAttempts
        showResults
        user {
          id
          name
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listQuizesByOrgIdForParticipant = /* GraphQL */ `
  query ListQuizesByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuizFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizesByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        name
        ownerId
        description
        duration
        multipleAttempts
        showResults
        questions {
          nextToken
        }
        user {
          id
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listQuestionsByQuizId = /* GraphQL */ `
  query ListQuestionsByQuizId(
    $quizId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionsByQuizId(
      quizId: $quizId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quizId
        type
        name
        point
        caseSensitive
        whiteSpaceSensitive
        options {
          nextToken
        }
        answers {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getQuiz = /* GraphQL */ `
  query GetQuiz($id: ID!) {
    getQuiz(id: $id) {
      id
      orgId
      name
      ownerId
      description
      duration
      multipleAttempts
      showResults
      graded
      user {
        id
        name
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      questions {
        items {
          id
          quizId
          type
          name
          point
          answer
          caseSensitive
          whiteSpaceSensitive
          createdAt
          updatedAt
          orderNumber
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        quizId
        type
        name
        point
        answer
        caseSensitive
        whiteSpaceSensitive
        options {
          items {
            correctAnswer
            id
            optionName
            orderNumber
          }
        }
        orderNumber
        createdAt
        updatedAt
        competencies {
          items {
            competencyId
            id
            area {
              areaName
            }
            competency {
              description
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const listQuizAttemptsByCourseUserId = /* GraphQL */ `
  query ListQuizAttemptsByCourseUserId(
    $courseUserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuizAttemptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizAttemptsByCourseUserId(
      courseUserId: $courseUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        courseUserId
        quiz {
          questions {
            items {
              id
              quizId
              type
              name
              point
              answer
              caseSensitive
              whiteSpaceSensitive
              createdAt
              updatedAt
              orderNumber
              options {
                items {
                  id
                  optionName
                  orderNumber
                }
              }
            }
            nextToken
          }
        }
        quizId
        questionId
        success
        answer
        answers
        attempt
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listQuizAttempts = /* GraphQL */ `
  query ListQuizAttempts(
    $filter: ModelQuizAttemptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizAttempts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        courseUserId
        quizId
        attempt
      }
      nextToken
    }
  }
`;

export const listProductsByOrgId = /* GraphQL */ `
  query ListProductsByOrgId(
    $orgId: ID!
    $productId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductOrgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsByOrgId(
      orgId: $orgId
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        product {
          id
          name
        }
      }
    }
  }
`;

export const listVMFilesByStatus = /* GraphQL */ `
  query ListVMFilesByStatus(
    $status: VM_FILE_STATUS!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVMFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVMFilesByStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        name
        createdAt
        status
        errorMessage
        fileSize
        file {
          bucket
          key
          region
        }
        userId
        orgId
        updatedAt
      }
      nextToken
    }
  }
`;

export const listTasksByLabId = /* GraphQL */ `
  query ListTasksByLabId(
    $labId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByLabId(
      labId: $labId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const byLabPrototypeId = /* GraphQL */ `
  query ByLabPrototypeId(
    $labPrototypeId: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLabInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byLabPrototypeId(
      labPrototypeId: $labPrototypeId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        instanceId
        labPrototypeId
        vAppId
        copiedFromID
        status
        poweredOn
        modulePartId
        userId
        assessmentId
        vms {
          nextToken
        }
        runningTime
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCourseMessagesByUserCourseId = /* GraphQL */ `
  query ListCourseMessagesByUserCourseId(
    $userCourseId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCourseMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseMessagesByUserCourseId(
      userCourseId: $userCourseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userCourseId
        userCourse {
          courseId
          userId
          orgId
          status
          courseProgress
          id
          createdAt
          updatedAt
          courseCourseUsersId
        }
        courseId
        course {
          orgId
          name
          description
          categoryId
          available
          ownerId
          availability
          status
          id
          createdAt
          updatedAt
        }
        senderId
        sender {
          id
          name
          type
          permissions
        }
        receiverId
        seen
        message
        createdAt
        updatedAt
        id
        courseUserMessagesId
      }
      nextToken
    }
  }
`;

export const listCourseMessages = /* GraphQL */ `
  query ListCourseMessages(
    $filter: ModelCourseMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        userCourseId
        userCourse {
          courseId
          userId
          orgId
          status
          courseProgress
          id
          createdAt
          updatedAt
          courseCourseUsersId
        }
        courseId
        course {
          orgId
          name
          description
          categoryId
          available
          ownerId
          availability
          status
          id
          createdAt
          updatedAt
        }
        senderId
        sender {
          id
          email
          name
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        receiverId
        seen
        message
        createdAt
        updatedAt
        id
        courseUserMessagesId
      }
      nextToken
    }
  }
`;

export const listRoleCompetencies = /* GraphQL */ `
  query ListRoleCompetencies(
    $filter: ModelRoleCompetencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoleCompetencies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        competencyKSAID
        competencyWorkRole {
          id
          roleCode
          workRole
          description
          areaId
          categoryId
          area {
            areaCode
            areaName
          }
          category {
            categoryName
          }
        }
        competencyKSA {
          id
          ksaCode
          description
          ksaType
        }
      }
      nextToken
    }
  }
`;

export const listUserAssessmentsByUserId = /* GraphQL */ `
  query ListUserAssessmentsByUserId(
    $userId: ID!
    $userAssessmentAssessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAssessmentsByUserId(
      userId: $userId
      userAssessmentAssessmentId: $userAssessmentAssessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userAssessmentAssessmentId
        assessment {
          id
          orgId
          creator {
            org {
              name
            }
          }
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          status
          multiLevel
          levels {
            items {
              tasks {
                items {
                  id
                  task {
                    id
                    recommendedPoints
                    attempts(filter: { success: { eq: true } }) {
                      items {
                        id
                        success
                        assessmentId
                        taskAttemptUserAssessmentId
                        taskAttemptTaskId
                        task {
                          recommendedPoints
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          tasks {
            items {
              id
              task {
                id
                recommendedPoints
                attempts(filter: { success: { eq: true } }) {
                  items {
                    id
                    success
                    assessmentId
                    taskAttemptUserAssessmentId
                    taskAttemptTaskId
                    task {
                      recommendedPoints
                    }
                  }
                }
              }
            }
          }
          createdAt
          updatedAt
        }
        groupId
        teamId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listQuizAttemptsByCourseId = /* GraphQL */ `
  query ListQuizAttemptsByCourseId(
    $courseId: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuizAttemptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizAttemptsByCourseId(
      courseId: $courseId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        courseUserId
        courseId
        userId
        quizId
        questionId
        success
        answer
        answers
        attempt
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
