import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { gql, useMutation, useQuery, useSubscription } from '@apollo/client';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import {
  FdCard,
  FdButton,
  FdModal,
  FdChip,
  FdIcons,
  FdTypography,
  FdAlert,
  FdProgress,
  AuthContext,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import { isPast, minutesToMilliseconds } from 'date-fns/esm';
import shortid from 'shortid';
import {
  createModulePartProgress,
  startLab,
  deleteLab,
  stopLab,
  updateCourseUser,
  updateModulePartProgress,
} from '../../graphql/mutations';
import {
  getLabTime,
  listLabInstancesByModulePartId,
  listModulePartProgressesByCourseUserId,
} from '../../graphql/queries';
import {
  formatMinutes,
  formatMinutesToHours,
  formatSingleDigit,
  getDateTimeZoneFormatted,
} from '../../shared/utils/dateUtils';
import LabVmsTable from './LabVmsTable';
import { LAB_INSTANCE_STATUS } from '../../constants';
import { getCourseUser } from '../../queries/customQueries';
import { updateCourseUserStatus } from '../../shared/utils/updateCourseUser';
import { onUpdateLabInstance } from '../../graphql/subscriptions';

function LabStart({ lab, userId, courseId }) {
  const { user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [resetLab, setResetLab] = useState(false);
  const [labInstance, setLabInstance] = useState(undefined);
  const { courseUserId, partId } = useParams();
  const { Warning, AccessAlarm, AvTimer } = FdIcons;
  const [refreshCount, setRefreshCount] = useState(0);

  const {
    data: courseUserData,
    loading: courseUserDataLoading,
    refetch: refetchCourseUser,
  } = useQuery(gql(getCourseUser), {
    variables: {
      userCourseId: courseUserId,
    },
    skip: !courseUserId,
  });

  const {
    loading: labInstancesLoading,
    startPolling,
    stopPolling,
    refetch: refetchLabInstances,
  } = useQueryRecursive(gql(listLabInstancesByModulePartId), {
    variables: {
      userId: { eq: user?.username },
      modulePartId: lab?.modulePartId,
    },
    skip: !user,
    onCompleted: (_data) => {
      const poweredOnLabInstance = _data?.byModulePartId?.items?.sort((a, b) =>
        b.updatedAt.localeCompare(a.updatedAt),
      )[0];
      setLabInstance(poweredOnLabInstance);
    },
    staleTime: { seconds: 0 },
  });

  const {
    data: labPrototypeTime,
    loading: labPrototypeLoading,
    refetch: refetchLabTime,
  } = useQuery(gql(getLabTime), {
    variables: {
      labInstanceId: labInstance?.id,
    },
    skip: !labInstance,
  });

  const {
    data: modulePartProgress,
    loading: modulePartProgressLoading,
    refetch: refetchModulePartProgress,
  } = useQueryRecursive(gql(listModulePartProgressesByCourseUserId), {
    variables: {
      courseUserId,
    },
  });

  const [startLabMutation, { loading: startLabLoading }] = useMutation(
    gql(startLab),
    {
      onCompleted: (_data) => {
        refetchLabInstances();
      },
    },
  );

  const [stopLabMutation, { loading: stopLabLoading }] = useMutation(
    gql(stopLab),
    {
      onCompleted: (_data) => {
        refetchLabTime();
        refetchLabInstances();
      },
    },
  );

  const [
    updateModulePartProgressMutation,
    { loading: updateModulePartProgressLoading },
  ] = useMutation(gql(updateModulePartProgress), {
    onCompleted: (_data) => {
      refetchModulePartProgress();
    },
  });

  const [
    createModulePartProgressMutation,
    { loading: createModulePartProgressLoading },
  ] = useMutation(gql(createModulePartProgress), {
    onCompleted: (_data) => {
      refetchModulePartProgress();
    },
  });

  const [updateCourseUserMutation] = useMutation(gql(updateCourseUser), {
    onCompleted: (_data) => {
      refetchCourseUser();
    },
  });

  const [resetLabMutation] = useMutation(gql(deleteLab), {
    onCompleted: (_data) => {
      refetchLabInstances();
    },
    fetchPolicy: 'cache-and-network',
  });

  useSubscription(gql(onUpdateLabInstance), {
    onSubscriptionData: (_data) => {
      refetchLabInstances();
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (
      labInstance &&
      (labInstance.status !== 'READY' ||
        labInstance.status !== 'DELETED' ||
        labInstance?.status !== 'OFF' ||
        labInstance?.status !== 'CLONE_FAILED')
    ) {
      startPolling(10000);
    }
    if (
      labInstance &&
      (labInstance.status === 'READY' ||
        labInstance.status === 'OFF' ||
        labInstance.status === 'DELETED' ||
        labInstance.status === 'CLONE_FAILED')
    ) {
      stopPolling();
    }
  }, [labInstance, startPolling, stopPolling]);

  useEffect(() => {
    return () => {
      setLabInstance(undefined);
      refetchLabInstances();
      refetchModulePartProgress();
    };
  }, [lab, refetchModulePartProgress, refetchLabInstances]);

  useEffect(() => {
    if (
      labInstancesLoading === false &&
      labPrototypeLoading === false &&
      modulePartProgressLoading === false &&
      courseUserDataLoading === false
    ) {
      const timeRemainingLeft =
        labPrototypeTime?.getLabTime?.timeRemaining || 0;
      if (timeRemainingLeft > 0) {
        refetchLabTime();
        refetchLabInstances();
        setTimeout(setRefreshCount(refreshCount + 1), 2000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCount]);

  if (
    labPrototypeLoading ||
    modulePartProgressLoading ||
    courseUserDataLoading ||
    (labInstancesLoading &&
      ![
        'BUILD_REQUESTED',
        'BUILDING',
        'CLONE_REQUESTED',
        'CLONING',
        'POWERING_ON',
        'POWERING_OFF',
      ].includes(labInstance?.status))
  )
    return <FdProgress />;

  const getVmsActions = (status, hasVdi) => {
    switch (status) {
      // TODO: will remove the comment when the backend work is done
      // for shutting down and starting the individual vm
      case LAB_INSTANCE_STATUS.OFF:
        return [
          // {
          //   label: 'start',
          //   show: true,
          //   onClick: () => {
          //     // TODO: CALL MUTATION TO START VM
          //     console.log('vm started');
          //   },
          // },
        ];
      case LAB_INSTANCE_STATUS.READY:
        return [
          {
            label: 'connect',
            show: () => hasVdi,
            onClick: (e, rowData) => {
              window.open(
                `/labs/connect/${rowData.labInstanceId}/vdi/${rowData.id}`,
              );
            },
          },
          // {
          //   label: 'snapshot',
          //   show: true,
          //   onClick: () => {
          //     // TODO: CALL MUTATION TO SNAPSHOT A VM
          //     console.log('vm snapshot');
          //   },
          // },
          // {
          //   label: 'shutdown',
          //   show: true,
          //   onClick: () => {
          //     // TODO: CALL MUTATION TO SHUTDOWN VM
          //     console.log('vm shutdown');
          //   },
          // },
        ];
      default:
        return [];
    }
  };

  const currentModulePartProgress =
    modulePartProgress?.listModulePartProgressesByCourseUserId?.items?.find(
      (modulePartProgressItem) =>
        modulePartProgressItem.modulePartId === partId,
    );

  const handleUpdateModulePartProgress = (status) => {
    const now = new Date().toISOString();
    const params =
      status === 'STARTED' ? { startedOn: now } : { finishedOn: now };
    updateModulePartProgressMutation({
      variables: {
        input: {
          id: currentModulePartProgress?.id,
          status,
          userId,
          courseId,
          ...params,
        },
      },
    });
  };

  const labVmsData = labInstance?.vms?.items?.map(
    ({ id, name, labInstanceId, hasVdi }) => ({
      id,
      hasVdi,
      labInstanceId,
      name,
      status: LAB_INSTANCE_STATUS[labInstance?.status] || labInstance?.status,
      actions: getVmsActions(LAB_INSTANCE_STATUS[labInstance?.status], hasVdi),
    }),
  );

  const actions = [
    {
      CustomElement: (row) => {
        const { rowData } = row;

        return (
          <Box display="flex">
            {rowData?.actions.map(({ label, show, onClick }) => (
              <Box key={shortid.generate()}>
                {show && (
                  <FdButton
                    variant="tertiary"
                    size="small"
                    onClick={(e) => onClick(e, rowData)}
                    key={label}
                  >
                    {label}
                  </FdButton>
                )}
              </Box>
            ))}
          </Box>
        );
      },
    },
  ];

  const timeRemaining = labPrototypeTime?.getLabTime?.timeRemaining || 0;
  const milliSecondsToFinish = minutesToMilliseconds(timeRemaining);

  const hasLabExpired =
    currentModulePartProgress?.modulePart?.expiry &&
    isPast(new Date(currentModulePartProgress?.modulePart?.expiry));
  const isLabInstanceOff = ['OFF', 'DELETED'].includes(labInstance?.status);
  const isLabInstanceLoading =
    labInstance?.status === 'BUILDING' ||
    labInstance?.status === 'POWERING_ON' ||
    labInstance?.status === 'POWERING_OFF';
  const isLabTimeLeft = timeRemaining > 0;
  const isLabCompleted = currentModulePartProgress?.status === 'FINISHED';
  const isLabInstanceReset =
    labInstance?.status === 'DELETE_REQUESTED' &&
    labInstance?.runningTime < lab?.duration;

  const showStartLab =
    (!labInstance && !startLabLoading) ||
    (labInstance?.status === 'DELETED' &&
      !startLabLoading &&
      labInstance?.runningTime < lab?.duration &&
      !hasLabExpired);

  return (
    <Box>
      <FdModal
        title={
          <Box display="flex" alignItems="center">
            <Warning
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Start Lab?</span>
          </Box>
        }
        size="xs"
        description={
          <Box>
            <FdTypography variant="subtitle1">
              Are you sure you want to start the lab?
            </FdTypography>
            <Box mt={1}>
              Your lab time will start to count down if you start the lab. You
              will not be able to access the lab after the lab time expires.
            </Box>
          </Box>
        }
        confirm="Start Lab"
        dismiss="Cancel"
        open={open}
        onConfirm={() => {
          startLabMutation({
            variables: {
              labPrototypeId: lab?.labId,
              modulePartId: partId,
            },
          });
          if (!currentModulePartProgress) {
            createModulePartProgressMutation({
              variables: {
                input: {
                  courseUserId,
                  modulePartId: partId,
                  status: 'STARTED',
                  startedOn: new Date().toISOString(),
                  userId,
                  courseId,
                },
              },
            });
          } else if (
            currentModulePartProgress &&
            currentModulePartProgress.status !== 'FINISHED'
          ) {
            handleUpdateModulePartProgress('STARTED');
            updateCourseUserStatus(
              modulePartProgress,
              updateCourseUserMutation,
              courseUserData,
              partId,
            );
          }
          setOpen(false);
        }}
        onDismiss={() => setOpen(false)}
      />

      <FdModal
        title={
          <Box display="flex" alignItems="center">
            <Warning
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Reset Lab?</span>
          </Box>
        }
        size="xs"
        description={
          <Box>
            <FdTypography variant="subtitle1">
              Are you sure you want to reset the lab?
            </FdTypography>
            <Box mt={1}>
              This will delete your current copy of the lab, including all of
              your work inside the lab.
            </Box>
          </Box>
        }
        confirm="Reset lab"
        dismiss="Cancel"
        open={resetLab}
        onConfirm={() => {
          resetLabMutation({
            variables: {
              labInstanceId: labInstance?.id,
            },
          });
          setResetLab(false);
        }}
        onDismiss={() => setResetLab(false)}
      />

      <FdCard
        variant="outlined"
        heading={lab?.name}
        summary={
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex">
              {lab?.expiry &&
                !hasLabExpired &&
                labInstance?.status !== 'READY' &&
                !isLabInstanceOff && (
                  <Box display="flex" alignItems="center" whiteSpace="nowrap">
                    <Box mr={1}>
                      <AccessAlarm />
                    </Box>
                    <FdTypography variant="body1">
                      {`Lab Expiry Time: ${getDateTimeZoneFormatted(
                        lab?.expiry,
                        true,
                      )}`}
                    </FdTypography>
                  </Box>
                )}
              <Box display="flex" alignItems="center">
                {!hasLabExpired && (
                  <Box
                    display="flex"
                    alignItems="center"
                    ml={2}
                    whiteSpace="nowrap"
                  >
                    {labInstance?.status === 'READY' ? (
                      <Box display="flex" alignItems="center">
                        <Box mr={1}>
                          <AvTimer />
                        </Box>
                        <Countdown
                          date={Date.now() + milliSecondsToFinish}
                          onComplete={() => {
                            // refresh refetchLabTime() until timeRemainingLeft is 0 or less than
                            setRefreshCount(1);
                          }}
                          renderer={({
                            days,
                            hours,
                            minutes,
                            seconds,
                            completed,
                          }) =>
                            !completed && (
                              <>
                                <FdTypography variant="body1">
                                  {`Lab Time Remaining: ${formatSingleDigit(
                                    days * 24 + hours,
                                  )}:${formatSingleDigit(
                                    minutes,
                                  )}:${formatSingleDigit(seconds)}`}
                                </FdTypography>
                              </>
                            )
                          }
                        />
                      </Box>
                    ) : isLabInstanceOff &&
                      labInstance?.staus !== 'CLONE_FAILED' ? (
                      // eslint-disable-next-line react/jsx-indent
                      <Box
                        display="flex"
                        alignItems="center"
                        whiteSpace="nowrap"
                      >
                        <Box mr={1}>
                          <AvTimer />
                        </Box>
                        <FdTypography variant="body1">
                          {`Lab Time Remaining: ${
                            isLabTimeLeft
                              ? formatMinutesToHours(timeRemaining)
                              : '00:00:00'
                          }`}
                        </FdTypography>
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        alignItems="center"
                        whiteSpace="nowrap"
                      >
                        <Box mr={1}>
                          <AvTimer />
                        </Box>
                        <FdTypography variant="body1">
                          {`Allocated Lab Time: ${formatMinutes(
                            lab?.duration,
                          )}`}
                        </FdTypography>
                      </Box>
                    )}
                  </Box>
                )}
                <Box ml={2} display="flex">
                  {labInstance?.status === 'READY' &&
                    !hasLabExpired &&
                    isLabTimeLeft && (
                      <FdButton
                        variant="secondary"
                        size="small"
                        style={{ marginRight: 8 }}
                        disabled={
                          startLabLoading ||
                          stopLabLoading ||
                          isLabInstanceLoading
                        }
                        onClick={() => {
                          setResetLab(true);
                        }}
                      >
                        Reset Lab
                      </FdButton>
                    )}
                  {labInstance &&
                    labInstance?.status !== 'CLONE_FAILED' &&
                    !hasLabExpired &&
                    isLabTimeLeft && (
                      <FdButton
                        variant="secondary"
                        size="small"
                        style={{ marginRight: 8 }}
                        disabled={
                          startLabLoading ||
                          stopLabLoading ||
                          isLabInstanceLoading
                        }
                        onClick={() => {
                          if (isLabInstanceOff) {
                            startLabMutation({
                              variables: {
                                labPrototypeId: lab?.labId,
                                modulePartId: partId,
                              },
                            });
                            return;
                          }
                          stopLabMutation({
                            variables: {
                              labInstanceId: labInstance?.id,
                            },
                          });
                        }}
                      >
                        {stopLabLoading ||
                        startLabLoading ||
                        isLabInstanceLoading
                          ? 'Loading...'
                          : isLabInstanceOff
                          ? 'Restart Lab'
                          : 'Shutdown Lab'}
                      </FdButton>
                    )}
                  {(labInstance || hasLabExpired || isLabCompleted) && (
                    <>
                      {isLabCompleted ? (
                        <FdChip
                          color="success"
                          size="small"
                          label="completed"
                        />
                      ) : (
                        <FdButton
                          size="small"
                          disabled={
                            startLabLoading ||
                            stopLabLoading ||
                            isLabInstanceLoading
                          }
                          onClick={() => {
                            if (!currentModulePartProgress) {
                              createModulePartProgressMutation({
                                variables: {
                                  input: {
                                    courseUserId,
                                    modulePartId: partId,
                                    status: 'FINISHED',
                                    finishedOn: new Date().toISOString(),
                                    userId,
                                    courseId,
                                  },
                                },
                              });
                              return;
                            }

                            handleUpdateModulePartProgress('FINISHED');
                            updateCourseUserStatus(
                              modulePartProgress,
                              updateCourseUserMutation,
                              courseUserData,
                              partId,
                            );
                          }}
                        >
                          {updateModulePartProgressLoading ||
                          createModulePartProgressLoading
                            ? 'Loading...'
                            : 'Mark Complete'}
                        </FdButton>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        }
        subHeading={lab?.description}
      >
        <Box mt={3}>
          {startLabLoading && (
            <Box mt={2}>
              <FdAlert
                alertTitle="Your lab is being built. This may take several minutes."
                variant="info"
                message="Building lab..."
              />
              <FdProgress size="small" />
            </Box>
          )}
          {showStartLab && !hasLabExpired && (
            <FdButton size="medium" onClick={() => setOpen(true)}>
              Start Lab
            </FdButton>
          )}
        </Box>
      </FdCard>
      {isLabCompleted && (
        <Box mb={2}>
          <FdAlert
            alertTitle="This lab has been marked as complete!"
            variant="success"
          />
        </Box>
      )}
      {isLabInstanceReset && (
        <Box mt={2}>
          <FdAlert
            alertTitle="Your lab is being reset. This may take several minutes."
            variant="info"
            message="Resetting Lab Instances..."
          />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={1}
          >
            <FdProgress />
          </Box>
        </Box>
      )}
      {(hasLabExpired || (labInstance && !isLabTimeLeft)) && (
        <FdAlert
          alertTitle={
            hasLabExpired ? 'This lab has expired' : 'Your lab time has expired'
          }
          variant="warning"
          message={
            hasLabExpired
              ? 'You cannot access this lab as the lab has expired'
              : 'You cannot access this lab as your lab time has expired.'
          }
        />
      )}
      {!['DELETED', 'CLONE_FAILED'].includes(labInstance?.status) &&
      labInstance?.vms?.items?.length === 0 ? (
        <Box mt={2}>
          <FdAlert
            alertTitle="Your lab instances vms are being built. This may take several minutes."
            variant="info"
            message="Building Lab Instances vms..."
          />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={1}
          >
            <FdProgress />
          </Box>
        </Box>
      ) : (
        !['DELETED', 'CLONE_FAILED'].includes(labInstance?.status) &&
        !hasLabExpired &&
        isLabTimeLeft &&
        !isLabInstanceReset && (
          <LabVmsTable rows={labVmsData} actions={actions} headerActions={[]} />
        )
      )}
    </Box>
  );
}

LabStart.propTypes = {
  lab: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    expiry: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    updatedAt: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    labId: PropTypes.string,
    modulePartId: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  courseId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

export default LabStart;
